import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { countries } from './countries';
import { AsYouType } from 'libphonenumber-js';
import './countrySelect.css';
const getCountryISO3 = require("country-iso-2-to-3");

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  if (isoCode) {
    // console.log(isoCode
    //         .toUpperCase()
    //         .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)));
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}
  // let iso3code = getCountryISO3(isoCode);
  // return (
  //   <img
  //     src={`assets/countryFlagImg/${iso3code ? iso3code.toLocaleLowerCase() : isoCode.toLocaleLowerCase()}.png`}
  //     alt='country-flag'
  //     className='flag-icon'
  //   /> 
  // )
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18
    },
  },
});

export default function CountrySelect(props) {
  const [inputValue, setInputValue] = React.useState('');
  const [keyDown, setKeyDown] = useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    if (props.numberToUpdate && !keyDown) {
      editNumberToUpdate(props.numberToUpdate);
    }
  }, [props.numberToUpdate]);

  const editNumberToUpdate = (number) => {
    const asYouType = new AsYouType();
    let fn = asYouType.input('+' + number);
    let countryCode = asYouType.getNumber() ? asYouType.getNumber().country : null;
    if (countryCode) {
      let countryFlag = countryToFlag(countryCode);
      setInputValue(countryFlag + fn);
    }
    else setInputValue(fn);
  }

  const editInpuValue = () => {
    // console.log(inputValue);
    const asYouType = new AsYouType();
    let input_copy = (' ' + inputValue).slice(1);
    let rn = input_copy.replace(/^\D+/g, '');
    asYouType.input('+');
    let number = asYouType.input(rn);
    let flag = inputValue.split('+')[0];
    if (flag && rn) {
      return flag + number;
    }
    return number;
  }

  return (
    <div className='ac-main'>
      {/* <img src='assets/countryFlagImg/usa.png'></img> */}
      <Autocomplete
        id="field7"
        size="small"
        options={countries}
        classes={{
          option: classes.option,
        }}
        style={props.style ? props.style : null}
        // defaultValue={`${countryToFlag(countries[0].code)} ${countries[0].phone}`}
        inputValue={editInpuValue()}
        onInputChange={(event, newInputValue) => {
          let rn = newInputValue.replace(/^\D+/g, '');
          props.getNumberToUpdate(rn.replace(/\s/g, ''));
          setInputValue(newInputValue);
        }}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.phone;
          }
          // Regular option
          return countryToFlag(option.code) + option.phone;
        }}
        freeSolo
        renderOption={(option) => (
          <React.Fragment>
            <span>{countryToFlag(option.code)}</span>
            {option.label} ({option.code}) {option.phone}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            onKeyDown={() => setKeyDown(true)}
            onKeyUp={() => setKeyDown(false)}
            {...params}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off', // disable autocomplete and autofill
              style: { height: 16 }
            }}
          />
        )}
      />
    </div>
  );
}
