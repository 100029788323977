import React, {Component} from 'react';
import './index.css';
import axios from '../../axios';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import {Link} from 'react-router-dom';

class EnterPassword extends Component {
    state = {
        isLoader: false
    }
    submit() {
        let params = new URLSearchParams(this.props.location.search)
        if (this.newPassword.value === this.verifyNewPassword.value) {
            this.setState({isLoader: true})

            axios.post('/Provisioning/CompleteResetPassword', {
                "requestid": params.get('verified'),
                "newpassword": this.newPassword.value
            }).then(response => {
                this.setState({isLoader: false})
                this
                    .props
                    .history
                    .push('/login')
            }).catch(error => {
                this.setState({isLoader: false})
                alert(error.response
                    ? error.response.data.Description||error.response.data.Message                    : error)
            });;

        } else {
            alert('Your Password are not equal')
        }
    }
    render() {

        return (
            <div className=" wrapper row justify-content-center m-0 ">
                <img src='assets/Capture.PNG' alt="" className="loginImg"/>
                <Form className="loginForm col-3">
                    <h4>Reset your password</h4>
                    <p className="font-weight-bold">Please select your new Password.<br/></p>
                    <FormGroup>
                        <Label for="newPassword">new Password</Label>
                        <Input
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            innerRef={(newPassword) => {
                            this.newPassword = newPassword
                        }}
                            placeholder="new Password"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="verifyNewPassword">verify your new Password</Label>
                        <Input
                            type="password"
                            name="verifyNewPassword"
                            id="verifyNewPassword"
                            innerRef={(verifyNewPassword) => {
                            this.verifyNewPassword = verifyNewPassword
                        }}
                            placeholder="verify Password"/>
                    </FormGroup>
                    <Button
                        style={{
                        backgroundColor: ' #2EC2FE'
                    }}
                        className="mb-4"
                        onClick={() => this.submit()}
                        size="lg"
                        block>Submit
                    </Button>
                    {this.state.isLoader?
                         <img style={{width:'60px',height:'50px',marginTop: 'auto'}} src='assets/spikkoloaderanimation.svg' alt="" className="loginImg"/>
                        : null}
                    <Link to="/login">
                        Cancel</Link>
                </Form>
            </div>
        );
    }
}

export default EnterPassword;