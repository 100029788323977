import React, {Component} from 'react';
import {Form, Col, Progress, Row, Label,Button} from 'reactstrap';
import './index.css';
import { Redirect } from 'react-router-dom'

class Success extends Component {
    state = {
        redirect:this.props.location.state&&this.props.location.state.redirect?this.props.location.state.redirect:false,

    }
    next(){
        this
                            .props
                            .history
                            .push('/selectPackage',{redirect:true,countId:this.props.location.state.countId});
    }
    render() {
        if(this.state.redirect){
        return (
            <div className=" wrapper row m-0 ">
                <div className="divCountImg">
                    <img src='assets/Capture3.PNG' alt="" className="countImg"/></div>
                <Form className="enterCodeForm">
                    <div className="mt-3">Step 3</div>
                    <Progress animated className="mb-3" value="100"/>
                    <Row className="my-5 text-center d-block">
                            {this.props.location.state && this.props.location.state.logo
                                ? <img
                                        style={{
                                        width:'150px'
                                    }}
                                        alt=""
                                        src={this.props.location.state.logo}/>
                                : null}
                        <Col className="text-center mt-4">
                            <Label className="font-weight-bold"><h4>{localStorage.getItem('email')}</h4></Label>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col className="text-center">
                            <h3>
                                Your user has been successfully created</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            className="m-auto"
                            style={{
                            backgroundColor: '#44d551',
                            borderRadius: '50%',
                            height: '40px',
                            width: '40px'
                        }}
                            onClick={() => this.next()}
                            block>
                            <img src='assets/checkmark.png' alt=""/>
                        </Button>
                    </Row>
                </Form>
            </div>
        );
    }else{
        return(<Redirect to='/login' />)
    }
    }
}

export default Success;