import axios from '../../axios';
import React, { useState, useEffect } from 'react';
import {
    Button,
    Col,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    Label,
    Row,
    ModalFooter,
    Spinner
} from 'reactstrap';
import * as STRINGS from '../../strings/english';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import './advancedFeatures/advance.css';
import './index.css';
import './transcriptionModal.css';

function TranscriptionModal(props) {

    const [allLanguages, setAllLanguages] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [checked, setChecked] = useState(false);
    const authorization = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('auth-data')
        }
    }
    const SUCCESS = 'Success';
    const IN_PROGRESS = 'InProgress'

    useEffect(() => {
        setAllLanguages(props.allLanguages);
        setSelectedLanguage(props.selectedLanguage);
    }, [])

    const transcribeNowOnClick = () => {
        if (checked) {
            setIsLoader(true);
            let body = {
                accountId: props.userData.AccountId,
                callId: props.rowData.CallId,
            };
            if (selectedLanguage.Code) {
                body['language'] = selectedLanguage.Code;
            }
            axios.post('/Call/TranscribeCall', body, authorization).then(response => {
                props.cancelOnClick();
            })
                .catch(error => {
                    setIsLoader(false);
                    alert(error.response
                        ? error.response.data.Description || error.response.data.Message : error)
                });
        }
        else alert(STRINGS.SELECT_TRANSCRIPTION_LANGUAGE);
    }

    return <React.Fragment>
        <ModalHeader cssModule={{ 'modal-title': 'w-100 text-center' }}>
            { props.isSummaryModal ? (props.status == null ? STRINGS.GET_CALL_SUMMARY : STRINGS.CALL_SUMMARY) 
            : (props.status == null ? (props.userData.Features.TranscriptionFeature.TranscribeAndSummarize ? STRINGS.GET_CALL_TRANSCRIPTION + ' & ' + STRINGS.GET_CALL_SUMMARY: STRINGS.GET_CALL_TRANSCRIPTION) : STRINGS.CALL_TRANSCRIPTION)}
        </ModalHeader>
        <ModalBody>
            {props.status == null && !props.isSummaryModal &&
                <React.Fragment>
                    {allLanguages.length > 0 && selectedLanguage.Name &&
                        <FormGroup className="">
                            <InputLabel htmlFor="field6" style={{ fontWeight: 'bold', fontSize: '14px', color: '#3c3f4a' }}>
                                {STRINGS.SELECT_TRANSCRIPTION_LANGUAGE}
                            </InputLabel>
                            <Autocomplete
                                id="field6"
                                className=""
                                style={{ width: '20rem' }}
                                options={allLanguages}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                }}
                                getOptionLabel={(option) => option.Name}
                                onChange={(event, newValue) => {
                                    if (newValue) {
                                        setSelectedLanguage(newValue);
                                    }
                                }}
                                value={selectedLanguage}
                                size="small"
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "new-password", // disable autocomplete and autofill
                                        }}
                                    />}
                            />
                        </FormGroup>}

                    <div style={{ marginTop: '3.2rem' }}>
                        <table className="table">
                            <thead className="fs-12 gray thead gr-mb">
                                <tr className="trh">
                                    <th style={{ paddingLeft: '0.75rem' }}>{STRINGS.USER}</th>
                                    <th style={{ paddingLeft: '0.7rem' }}>{STRINGS.LANGUAGE}</th>
                                    <th>{STRINGS.DURATION}</th>
                                    <th>{STRINGS.PRICE_FOR_MIN}</th>
                                    <th>{STRINGS.TOTAL_PRICE}</th>
                                </tr>
                            </thead>
                            <tbody style={{}} className="tbody">
                                <tr
                                    className='tr fs-14'
                                    style={{ paddingLeft: '0', color: '#3c3f4a' }}
                                >
                                    <td className='th-3-points'>{props.rowData.Username}</td>
                                    <td className=''>{inputValue}</td>
                                    <td className=''>{props.rowData.BilledMinutes}</td>
                                    <td className=''>{(props.userData.Features.TranscriptionFeature.TranscribeAndSummarize ? props.userData.Features.TranscriptionFeature.PPMSummary + props.userData.Features.TranscriptionFeature.PPM : props.userData.Features.TranscriptionFeature.PPM).toFixed(3)}</td>
                                    <td className=''>{(props.rowData.BilledMinutes * (props.userData.Features.TranscriptionFeature.TranscribeAndSummarize ? props.userData.Features.TranscriptionFeature.PPMSummary + props.userData.Features.TranscriptionFeature.PPM : props.userData.Features.TranscriptionFeature.PPM)).toFixed(3) + '$'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>}

            {props.status == SUCCESS && !props.isSummaryModal &&
                <React.Fragment>
                    <a
                        className='has-transcribe-link has-transcribe-poppins'
                        href={props.transcriptionData.SecuredTranscriptionURL}
                        target='_blank'
                    >
                        {STRINGS.TRANSCRIPTION_LINK}
                    </a>
                    {/* <div className='has-transcribe-poppins has-transcribe-show-language-header'>
                        {STRINGS.TRANSCRIPTION_LANGUAGE}
                    </div>
                    <div className='has-transcribe-poppins has-transcribe-show-language'>
                        {'British English'}
                    </div> */}
                </React.Fragment>}

            {props.status == SUCCESS && props.isSummaryModal &&
                <React.Fragment>
                    <div className='has-transcribe-poppins'>
                        {props.transcriptionData.Summary}
                    </div>
                </React.Fragment>}

            {props.status == IN_PROGRESS &&
                <React.Fragment>
                    <div className='has-transcribe-poppins has-transcribe-in-progress'>
                        {`${STRINGS.IN_PROGRESS}...`}
                        <Spinner style={{ color: "#2EC2FE" }} />
                    </div>
                </React.Fragment>}

        </ModalBody>
        <ModalFooter style={{ display: "block" }}>
            {props.status == null && !props.isSummaryModal && 
                <Row className='ml-3'>
                    <Col md={4}>
                        <Label check style={{ fontFamily: 'Poppins', color: '#3c3f4a', fontSize: '14px' }}>
                            <Input
                                type='checkbox'
                                checked={checked}
                                onChange={(e) => setChecked(e.target.checked)}
                                style={{ marginTop: '4px' }}
                            />
                            {STRINGS.CONFIRM_TRANSCRIPTION}
                        </Label>
                    </Col>
                </Row>}
            <FormGroup row className="justify-content-end mt-4">
                <Col md={4}>
                    <Button className="btnColor5 fontColor fs-14 no-border" style={{ width: "100%" }}
                        size="lg"
                        onClick={props.cancelOnClick}
                    >
                        {STRINGS.CANCEL}
                    </Button>
                </Col>
                {props.status == null && !props.isSummaryModal &&
                    <Col md={4}>
                        <Button className="btnColor2 white fs-14 no-border btnColor2" style={{ width: "100%" }}
                            size="lg"
                            disabled={!checked}
                            onClick={transcribeNowOnClick}
                        >{STRINGS.TRANSCRIBE_NOW}
                        </Button>
                    </Col>}
            </FormGroup>

        </ModalFooter>
        {isLoader
            ? <img
                style={{
                    width: '60px',
                    height: '50px',
                    marginLeft: 'auto',
                    marginRight: 'auto'
                }}
                src='assets/spikkoloaderanimation.svg' />
            : null}
    </React.Fragment>
}

export default TranscriptionModal